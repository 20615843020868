import { useGlobalStore } from "@/stores/globalStore";
import { useSessionStore } from "@/stores/sessionStore";

export function showAlertMsg(msg = "Accion procesada con exito", time = 4000) {
    alertMessage.value = msg;
    alertMessageColor.value = "success";
    dialogAlertMessage.value = true;
    useGlobalStore().desactiveLoader();
    setTimeout(function () {
      alertMessage.value = {};
      alertMessageColor.value = "";
      dialogAlertMessage.value = false;
    }, time);
  }
  
  export function showWarning(e, time = 3000) {
    alertMessageColor.value = "orange-darken-4";
    alertMessage.value = e ?? "Hubo un error";
    dialogAlertMessage.value = true;
    useGlobalStore().desactiveLoader();
    setTimeout(function () {
      alertMessage.value = {};
      alertMessageColor.value = "";
      dialogAlertMessage.value = false;
    }, time);
  }
  
  export function showBuildError(msg) {
    alertMessage.value = msg;
    alertMessageColor.value = "error";
    dialogAlertMessage.value = true;
    useGlobalStore().desactiveLoader();
    setTimeout(function () {
      alertMessage.value = {};
      alertMessageColor.value = "";
      dialogAlertMessage.value = false;
    }, 4000);
  }
  
  export async function showError(e, time = 5000) {
    if(e?.response?.status === 401){
      useGlobalStore().buildLoader = false;
      await useSessionStore().clearStore();
      throw new Error("Sesión inválida.");
    }
  
    alertMessageColor.value = "error";
    alertMessage.value = e.response?.data?.errors ??
    e.response?.data?.message ?? e.message ?? "Hubo un error";
    dialogAlertMessage.value = true;
    useGlobalStore().desactiveLoader();
    setTimeout(function () {
      alertMessage.value = {};
      alertMessageColor.value = "";
      dialogAlertMessage.value = false;
    }, time);
  }
  
  export function showSistemMsg(msg) {
    const debug = import.meta.env.debug;
    if(useSessionStore().isRoot && debug){
      console.log(msg);
    }
  }
  
  export function showSistemError(e) {
    if(useSessionStore().isRoot){
      console.error(e.msg);
      console.log(e.event);
    }
  }
  