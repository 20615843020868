import axios from "axios";
import { useSessionStore } from "@/stores/sessionStore";
import { useModelStore } from "@/stores/modelStore";
import { usePosStore } from "@/stores/posStore";
import { useGlobalStore } from "@/stores/globalStore";

/** Create an Axios instance. */
function createService() {
  // Create an axios instance named "service"
  const service = axios.create();

  // Request interceptor
  service.interceptors.request.use(
    async (config) => {
      return config;
    },

    // Failed to send request
    (error) => Promise.reject(error)
  );

  // Response interceptor (Adjust according to specific business requirements)
  service.interceptors.response.use(
    (response) => {
      // apiData is the data returned by the API
      const apiData = response.data;
      return apiData;
    },

    (error) => {
      // "status" is the HTTP status code
      const status = error?.response?.status ?? undefined;
      const config = error.config; // Almacenar la configuración original de la solicitud
      // console.log('error: ')
      // console.log(error)

      switch (status) {
        case 400:
          if(!error.response.data) return retryRequest(error, config);

          error.message = "Bad request";
          break;
        case 401:
          // When the token expires, log out and force refresh the page (will redirect to the login page)
          useSessionStore().clearStore();
          break;
        case 403:
          error.message = "Forbidden";
          break;
        case 404:
          error.message = "Request address not found";
          break;
        case 408:
          error.message = "Request timeout";
          break;
        case 500:
          return retryRequest(error, config);
          error.message = "Internal server error";
          break;
        case 501:
          error.message = "Service not implemented";
          break;
        case 502:
          error.message = "Bad gateway";
          break;
        case 503:
          return retryRequest(error, config);
          error.message = "Service unavailable";
          break;
        case 504:
          error.message = "Gateway timeout";
          break;
        case 505:
          error.message = "HTTP version not supported";
          break;
        default:
          error.message = "Hubo un error desconocido.";
          break;
      }

      httpError(error);
      return Promise.reject(false);
    }
  );
  return service;
}

function createRequestFunction(service) {
  return async function (configRequest) {
      // SI ES UN ARRAY
      if (Array.isArray(configRequest)) {
        // Make all the requests in parallel
        const responses = await axios.all(configRequest.map(async (config) => {
          const result = await formatPayload(configRequest);
          return service(result)
        }));
        return responses.map((response) => response.result);
      }

      // SI ES UN REGISTRO UNICO
      const result = await formatPayload(configRequest);
      const response = await service(result);

      if(result.method === "put") console.log("Registro actualizado!");

      if(!response) return false;
      if (response.data) {
        return response.data.result;
      }
      return response.result;

  };
}

/** Instance for network request */
export const service = createService();

/** Method for network request */
export const request = createRequestFunction(service);
// export const request = isOffline() ? pwaRquestManager('No anda') : createRequestFunction(service);

/**
 * Función para reintentar la solicitud en caso de error 403
 * @param {Object} config - La configuración original de la solicitud
 * @returns {Promise} - El resultado de la solicitud o el error si falla tras varios intentos
 */
function retryRequest(error, config) {
  const maxAttempts = config.maxAttempts || 2;
  const retryInterval = config.retryInterval || 1000;

  // Si el intento actual no está registrado, lo inicializamos en 1
  config.attempt = config.attempt || 1;

  if (config.attempt <= maxAttempts) {
    // console.log(`Error en el intento ${config.attempt}: ${error.message}`);
    // Retardamos el reintento
    return new Promise((resolve) => {
      setTimeout(() => {
        // Incrementamos el número de intento en config
        config.attempt++;
        resolve(axios.request(config));
      }, retryInterval);
    }).then((response) => {
      // Reiniciamos el contador de intentos si la solicitud es exitosa
      config.attempt = 1;
      return response;
    }).catch((err) => {
      // Reintentar con el error actualizado
      return retryRequest(err, config);
    });
  } else {
    // Devolvemos el error si se alcanza el máximo de intentos
    // console.log("Número máximo de intentos alcanzado. Error final:", error.message);
    useGlobalStore().desactiveLoader();
    return Promise.reject(error);
  }
}

/** FUNCION PARA RECUPERAR REGISTROS PAGINADOS */
export async function getPaginatedRecords(model, data){
  const modelStore = useModelStore();
  const posStore = usePosStore();
  const sessionStore = useSessionStore();
  const accountDefault = {
    account_id: sessionStore.account.id,
    company_id: posStore.selectedCompany.id,
    branch_id: posStore.selectedBranch.id,
    companyConfig: posStore.selectedCompany.config,
    point_of_sale_id: posStore.selectedPos.id,
    warehouse: posStore.selectedWarehouse,
    user_id: sessionStore.user.id,
    entity_employee_id: sessionStore.user.entity.entity_employee.id,
  }

  let currentPage = data.current_page;
  let lastPage = data.last_page;
  let nextPageUrl = data.next_page_url
  for (let i = currentPage; i < lastPage; i++) {
    try {
      let res = await axios.post(nextPageUrl, accountDefault);

      currentPage = res.data.result.current_page;
      nextPageUrl = res.data.result.next_page_url;
      modelStore[model] = [...modelStore[model], ...res.data.result.data];
    } catch (e) {
      httpError(e);
    }
  }
}

// FORMATEA EL REQUEST
async function formatPayload(configRequest) {
  let hasFile = false;
  if(configRequest.data?.item){
    const selectedItem = configRequest.data.item;
    // Verificar si hay archivos en el objeto item
    for (const key in selectedItem) {
      if (selectedItem[key] instanceof File) {
        hasFile = true;
        break;
      }
    }

    // Si hay archivos, convertir a FormData
    if (hasFile) {
      const formData = new FormData();
      for (const key in selectedItem) {
        const value = selectedItem[key];
        // Manejar valores null o undefined
        if (value instanceof File) {
          // const img = resizeImage(value);
          // const newImage = await urltoFile(img, "testImg.png");
          formData.append(`item[${key}]`, value); // Añadir archivo
        } else if (value === null || value === undefined) {
          formData.append(`item[${key}]`, ''); // Enviar como cadena vacía
        } else if (typeof value === 'object') {
          formData.append(`item[${key}]`, JSON.stringify(value)); // Serializar objetos
        } else {
          formData.append(`item[${key}]`, value); // Añadir valores primitivos
        }
      }

      configRequest.method = 'post';
      formData.append('_method', 'PUT');
      configRequest.data = formData;
    }
  }
  return addData(configRequest, hasFile);
}

// AÑADE EL HEADER Y LA CONFIGURACION PREDETERMINADA
function addData(configRequest, hasFile){
  const sessionStore = useSessionStore();
  const posStore = usePosStore();
  const configHeaders = {
    headers: {
      Authorization: "Bearer " + sessionStore.token,
      Accept: "application/json",
      "Content-Type": hasFile ? "multipart/form-data" : "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
    baseURL: import.meta.env.VITE_BaseApiURL,
    data: {},
  };
  const accountDefault = {
    account_id: sessionStore.account.id,
    company_id: posStore.selectedCompany.id,
    branch_id: posStore.selectedBranch.id,
    companyConfig: posStore.selectedCompany.config,
    point_of_sale_id: posStore.selectedPos.id,
    warehouse: posStore.selectedWarehouse,
    user_id: sessionStore.user.id,
    entity_employee_id: sessionStore.user.entity.entity_employee.id,
  }

  // Combinar accountDefault con configRequest.data
  if (hasFile) {
    // Si es FormData, añadir los campos de accountDefault al FormData
    for (const key in accountDefault) {
      const value = accountDefault[key];
      configRequest.data.append(key, typeof value === 'object' ? JSON.stringify(value) : value);
    }
  } else {
    // Si es JSON, combinar normalmente
    configRequest.data = { ...accountDefault, ...configRequest.data };
  }

  return { ...configHeaders, ...configRequest };
}